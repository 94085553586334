// src/components/NavBar.js
import React from 'react';
import './smallimage.css';
import logo from '../assets/aA.png';

const NavBar = () => (
  <nav className="navbar">
    <div className="container navbar-content">
      <img src={logo} alt="Astro" className="logo" />
      <div className="navbar-info">
        <span>Call Us: (940) 363-1571</span>
       {/* <a href="#contact" className="quote-button">Get a Con</a>*/}
      </div>
    </div>
  </nav>
);

export default NavBar;
