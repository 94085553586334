import React from 'react';
import './About.css';
import image1 from '../assets/IMG_0014.jpg';
import image2 from '../assets/IMG_0018.jpg';
import image3 from '../assets/IMG_0019.jpg';

const About = () => (
  <section className="about">
    <div className="container">
    <h2 >About Us</h2>
    <p style={{ marginBottom: '20px' }}>DFW-based sandblasting service built for any application or job,
    offering <br></br>precision and quality to meet all your surface preparation needs.</p>
      <div className='top'>
        <div className='textT'>
        <p className='TitleP'>Our Services</p>
        <p className='SubtitleP'>Comprehensive Solutions for Every Need</p>
        <p className='TextP'>
          We specialize in a range of cleaning and restoration services tailored to various industries and needs. Our experienced team ensures high-quality service for all your requirements.
        </p>

        <ul className='ServiceList'>
          <li><strong>Automotive Restoration:</strong> Bringing vehicles back to their former glory with meticulous attention to detail.</li>
          <li><strong>Graffiti Removal:</strong> Efficiently removing graffiti to restore surfaces to their original condition.</li>
          <li><strong>Marine Maintenance:</strong> Providing maintenance solutions to keep marine equipment and surfaces in top shape.</li>
          <li><strong>Concrete Cleaning:</strong> Professional concrete cleaning for commercial and residential properties.</li>
          <li><strong>Wood Restoration:</strong> Revitalizing wood surfaces to enhance durability and appearance.</li>
          <li><strong>Heavy Equipment Cleaning:</strong> Cleaning and maintaining heavy machinery to ensure optimal performance.</li>
          <li><strong>Fire Damage Restoration:</strong> Restoring fire-damaged areas to bring back safety and usability.</li>
          <li><strong>Powder Coating Removal:</strong> Safely removing powder coatings to prepare surfaces for new finishes.</li>
        </ul> 
          
        </div>
        <div className='imageT'>
                    <img src={image1} className='Timage'></img>
                    
        </div>
      </div>
      <div className='bottom'>
      <div className='imageTT'>
        <img src={image2} className='Timage'></img>
      </div>
      <div className='textT'>
      <p className='TitleP'>Northern DFW Sandblasting Experts</p>
      <p className='SubtitleP'>Professional Sandblasting Services in the Northern DFW Area</p>
      <p className='TextP'>
        Located in the northern DFW area, we offer specialized sandblasting services tailored to meet the needs of our local community. Sandblasting is an effective method for cleaning, restoring, and preparing surfaces by removing rust, paint, and contaminants, leaving a smooth and clean finish.
      </p>
      <p className='TextP'>
        Our team is skilled in handling a wide range of sandblasting projects. Sandblasting is ideal for jobs such as automotive restoration, graffiti removal, concrete cleaning, and heavy equipment maintenance. Whether you’re looking to restore an old vehicle, remove tough graffiti, or prepare a surface for a fresh coat of paint, our sandblasting services provide precision and quality you can rely on.
      </p>

      </div>
      </div>
    </div>
  </section>
);

export default About;
/*<p>
        DFW-based sandblasting service built for any application or job, 
        offering precision and quality to meet all your surface preparation needs.
      </p> */