import React from 'react';
import logoCom from '../assets/logo.png';
import './largeimage.css';

const Hero = () => {
  const openNewTab = () => {
    window.open('https://www.facebook.com/profile.php?id=61568134181467', '_blank'); // Replace with the actual URL
  };

  return (
    <section className="hero">
      <img src={logoCom} alt="Astro" className="logoT" />
      <p>Bringing surfaces to life with precision and quality sandblasting.</p>
      
      <button onClick={openNewTab}>Facebook</button>
    </section>
  );
};

export default Hero;
